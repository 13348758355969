#landing-area {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #ffffff;
}

#box-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    clip-path: polygon(0 0, calc(100% - 10vh) 0, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(0 0, calc(100% - 10vh) 0, 100% 100%, 0 100%);
    margin-right: -4.2vh;
    padding: 5px 11vh 5px 5px;
    background-color: transparent;
    background-image: url('./assets/clover_nursery.jpg');
    text-align: center;
}

#box-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(0% + 10vh) 100%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, calc(0% + 10vh) 100%);
    margin-left: -4.2vh;
    padding: 5px 5px 5px 11vh;
    background-color: transparent;
    background-image: url('./assets/petar-tonchev-c-5-QE5kBYk-unsplash.jpg');
    text-align: center;
}

.landingButton {
    padding: 2rem;
    border-radius: 35px;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 3rem;
    background-color: #00704a;
    opacity: 0.9;
    color: white;
}

.landingButtonNursery {
    padding: 2rem;
    border-radius: 35px;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 3rem;
    background-color: #1783c0;
    opacity: 0.9;
    color: white;
}

#mobile-diagonal {
    display: none;
}

.phoneButton {
    margin: 25px auto;
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    color: white;
    border-radius: 35px;
    padding: 20px;
    font-size: 2rem;
    border: 1px solid black;
}

.phoneButton :hover {
    background-color: #6db0a7;
};
.phoneButton > span {
    text-transform: none;
    font-size: 1rem;
}

.phoneButton > svg {
    margin-top: 5px;
    transform: scale(1.25);
}

.cloverLogo {
    width: 200px;
    margin: 10px;
}

.nurseryPhoneButton {
    background-color: #1783c0;
}

.landscapePhoneButton {
    background-color: #00704a;
}

@media (max-width: 600px) {
    #landing-area {
        flex-direction: column;
    }

    #box-left,
    #box-right {
        width: 100%;
        height: 50vh;
        margin: auto;
        /* clip-path: none; */
        padding: 0px;
        -webkit-clip-path: none;
        /* transform: skewY(15deg); */
    }

    /* #mobile-diagonal {
        display: flex;
        transform: skewY(15deg);
        height: 10px;
        background: #ffffff;
    } */

    #landing-area {
        background-color: #00704a;
    }

    .landingButton, .landingButtonNursery {
        width: 95%;
        font-size: 1.8rem;
        padding: 1rem;
        border: 1px solid black;
    }

    .cloverLogo {
        /* margin-top: 50px; */
        width: 170px;
    }

    .phoneButton {
        margin: 20px;
        font-size: 1.5rem;
        height: 30px;
    }

    #box-right {
        border-top: 10px solid white;
    }

}